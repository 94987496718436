<template>
  <div class="flex items-center justify-center text-xs p-1 w-20 h-5 rounded-full flex-none"
       :class="bgColorClass">
    <svg class="flex-shrink-0" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle id="Ellipse 1" cx="4" cy="4" r="4" :fill="fillColor"/>
    </svg>
    <span class="ml-1">
      {{ text }}
    </span>
  </div>
</template>
  
  <script>
  export default {
    name: 'StatusLabel',
    props: {
      text: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'danger'
      }
    },
    computed: {
      bgColorClass() {
        switch(this.type) {
          case 'warning':
            return 'bg-yellow-200 text-gray-500'; 
          case 'success':
            return 'bg-green-200 text-green-500';
          case 'danger':
          default:
            return 'bg-red-200 text-red-500';
        }
      },
      fillColor() {
        switch(this.type) {
          case 'warning':
            return '#FBD768';
          case 'success':
            return '#23B47E';
          case 'danger':
          default:
            return '#C53D62';
        }
      }
    }
  }
  </script>
  