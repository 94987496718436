<template>
    <div class="mt-24 w-full md:w-3/4 lg:w-3/4 xl:w-3/4 2xl:w-2/3 mx-auto p-2 flex flex-col justify-center items-center max-w-[840px]">
        <div class="mb-4">
            <a @click="navigateHome">
                <img src="@/assets/img/qadirlogo.png" width="150" />
            </a>
        </div>
        <div class="w-full lg:w-3/4 xl:w-3/4 2xl:w-2/3 bg-white pt-6 pb-6 pl-6 pr-6 md:pt-12 md:pb-12 md:pl-20 md:pr-20 rounded-md shadow-md">
    <slot></slot>
</div>


    </div>
</template>


<script>
export default {
    methods: {
        navigateHome() {
            this.$router.push('/');
        },
    },
}
</script>
