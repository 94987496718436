<template>
    <div class="py-8">
        <div>
            <div class="w-full h-[1px] bg-gray-500 bg-opacity-30"></div>
        </div>
    </div>
    <div class="flex justify-center">
        <a class="text-gray-500 underline text-sm flex items-center space-x-2 cursor-pointer"
            @click="isModalVisible = true">
            <img src="../../assets/img/designassets/Email.svg" alt="Icon" class="h-5 w-5" />
            <span>Contact Qadir Collections</span>
        </a>
    </div>
    <QadirContact v-if="isModalVisible" @close="isModalVisible = false" />
</template>

<script>
import QadirContact from '../../components/QadirContact.vue';

export default {
    components: {
        QadirContact,
    },
    data() {
        return {
            isModalVisible: false,
        }
    }
}
</script>
