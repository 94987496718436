import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../pages/LandingPage.vue";
import NoCase from "../pages/NoCase.vue";
import CaseAlreadyPaid from "../pages/CaseAlreadyPaid.vue";
import DisputeRegistered from "../pages/DisputeRegistered.vue";
import ConfirmInstallment from "../pages/ConfirmInstallment.vue";
import apiClient from "../services/apiClient";

import store from "../store";
const routes = [
  {
    path: "/",
    name: "NoCase",
    component: NoCase,
  },
  {
    path: "/lp/:id",
    name: "LandingPage",
    component: LandingPage,
    props: true,
    beforeEnter: async (to, from, next) => {
      store.dispatch("startRequest"); // Start request

      try {
        await store.dispatch("fetchInvoiceData", to.params.id);

        if (
          store.state.invoice.invoiceState === 1 ||
          store.state.invoice.invoiceState === 2
        ) {
          next({ name: "CaseAlreadyPaid", params: { id: to.params.id } });
        } else if (
          store.state.invoice.invoiceState === 3 ||
          store.state.invoice.invoiceState === 8
        ) {
          next({ name: "DisputeRegistered", params: { id: to.params.id } });
        } else if (store.state.invoice.invoiceState === 4) {
          next({ name: "ConfirmInstallment", params: { id: to.params.id } });
        } else {
          next();
        }
      } catch (error) {
        console.error(error);
        next(false); // Handle navigation failure
      } finally {
        store.dispatch("endRequest"); // End request, ensuring it's called in all scenarios
      }
    },
  },
  {
    path: "/case-already-paid/:id",
    name: "CaseAlreadyPaid",
    component: CaseAlreadyPaid,
  },
  {
    path: "/case-disputed/:id",
    name: "DisputeRegistered",
    component: DisputeRegistered,
  },
  {
    path: "/confirmed-installment/:id",
    name: "ConfirmInstallment",
    component: ConfirmInstallment,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Set tracking status based on the query parameter
  if (to.query.track === 'false') {
    store.commit('SET_TRACKING_STATUS', false);
  } else {
    store.commit('SET_TRACKING_STATUS', true);
  }

  // Check for '/lp' path with 'caseid' query parameter
  if (to.path === '/lp' && to.query.caseid) {
    try {
      // Make API call to fetch data based on caseid
      const response = await apiClient.get(`/Invoice/case-reference/${to.query.caseid}`);
      const invoiceId = response.data.id;

      // Redirect with the fetched invoice ID
      next({ path: `/lp/${invoiceId}`, query: to.query });
    } catch (error) {
      console.error('Error fetching invoice data:', error);
      next({ name: "NoCase" }); // Redirect to a fallback route in case of an error
    }
    return;
  }

  // Existing logic for routes requiring an ID
  if (
    to.path.match(
      /\/(lp|case-already-paid|case-disputed|confirmed-installment)\/\w+/
    )) {
    // Redirect to 'NoCase' if the 'id' param is not provided
    if (!to.params.id) {
      next({ name: "NoCase" });
    } else {
      next();
    }
  } else {
    next(); // Proceed with routes that do not require an 'id'
  }
});


export default router;
