<!-- RegisterDispute.vue -->
<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="$emit('close')"></div>
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div v-if="result === 'Success'" class="p-4 space-y-4">
          <div class="flex items-center justify-center mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="9" stroke="#23B47E" />
              <path d="M8 12L11 15L16 9" stroke="#23B47E" />
            </svg>
          </div>
          <p class="text-center text-gray-500">
            Thanks for submitting your dispute. Your case will be paused after a case worker has verified your dispute.
          </p>
        </div>
        <div v-else class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <h3 v-if="result !== 'Success'" class="text-lg leading-6 font-medium text-gray-900">
            Dispute debt
          </h3>
          <div v-if="result !== 'Success'">
            <p class="mt-4">
              If you think there has happened a mistake, or you are not legally obliged to pay the balance, please dispute
              the debt by providing a detailed explanation below
            </p>
            <div class="mt-6">
              <label for="disputeCategory" class="text-gray-500 block text-sm font-medium mt-2">
                Why do you dispute this claim?
              </label>
              <select id="disputeCategory" v-model="selectedDisputeCategory" @change="updateDropdownColor"
  class="mt-2 w-full h-9 border border-gray-300 rounded shadow bg-white pl-2 text-black">
  <option v-if="!selectedDisputeCategory" disabled value="">Select option</option>
  <option v-for="(value, key) in disputeCategories" :value="key" :key="key">
    {{ value }}
  </option>
</select>

            </div>
            <p class="mt-4"></p>
            <label for="dispute" class="text-gray-500 block text-sm font-medium mt-2">
              Please explain your dispute in details:
            </label>
            <textarea id="dispute" v-model="disputeText" @input="validateDisputeText"
              class="mt-2 block w-full h-32 rounded-lg border border-gray-400 bg-white shadow-inner p-2"
              placeholder="Explain your dispute here...">
            </textarea>
            <p :class="['text-sm', textLength < 50 ? 'text-owed-money' : 'text-green-500']">{{ textLength }} chars (50
              min)</p>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button v-if="result !== 'Success'" type="button" @click="submitDispute" :disabled="!canSubmit"
            class="mt-3 w-full sm:w-auto inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 bg-main-cta text-sm sm:text-xs font-semibold text-white hover:bg-main-cta-dark focus:outline-none sm:mt-0 sm:ml-3 sm:text-sm"
            :class="{ 'opacity-50 cursor-not-allowed': !canSubmit }">
            Send dispute
          </button>

          <button type="button" @click="$emit('close')"
            class="mt-3 w-full inline-flex justify-center rounded-lg border border-blue-600 shadow-sm px-4 py-2 bg-transparent text-blue-600 hover:bg-blue-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Back to overview
          </button>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "../../services/apiClient";

export default {
  name: 'RegisterDispute',
  props: ['invoiceId'],
  data() {
    return {
      disputeText: '',
      textLength: 0,
      loading: false,
      result: null,
      selectedDisputeCategory: null,
      disputeCategories: {
        0: 'Contract is cancelled',
        1: 'Contract was regretted',
        2: 'Amount already paid',
        3: 'Amount is wrong',
        4: 'Service/product not delivered',
        5: 'No recognition of debt',
        6: 'No invoice sent',
        7: 'Other'
      }
    }
  },
  computed: {
    canSubmit() {
      return this.textLength >= 50 && this.selectedDisputeCategory !== '';
    },
    selectClass() {
      return this.selectedDisputeCategory === '' ? 'placeholder-gray' : 'default-color';
    },
  },
  methods: {
    validateDisputeText() {
      this.textLength = this.disputeText.length
    },
    async submitDispute() {
      this.$emit('loading', true);
      try {
        var requestBody = {
          invoiceId: this.invoiceId,
          dispute: this.disputeText,
          category: this.selectedDisputeCategory
        };

        await apiClient.post('/DisputeRequest', requestBody);
        this.result = 'Success'
      } catch (error) {
        this.result = `Error: ${error.response.data}`
      } finally {
        this.$emit('loading', false);
      }
    },
    resetForm() {
      this.disputeText = ''
      this.textLength = 0
      this.result = null
      this.selectedDisputeCategory = null
    }
  }
}
</script>


<style scoped>
.placeholder-gray {
  color: #A0AEC0;
  /* Tailwind CSS gray-500 color */
}

.default-color {
  color: black;
}
</style>
