import apiClient from "../services/apiClient";
import { ref } from "vue";
import { computed } from "vue";

export default function useEventTracking(invoiceId, store) { 
  const shouldTrack = computed(() => store ? store.state.shouldTrack : true);
  const userIp = ref("");

  const fetchIp = async () => {
    try {
      const response = await fetch("https://www.cloudflare.com/cdn-cgi/trace");
      const data = await response.text();
      const ipMatch = data.match(/ip=(.+)/);
      if (ipMatch) {
        userIp.value = ipMatch[1];
        return userIp.value;
      }
      return ""; // Return empty string if no match found
    } catch (error) {
      console.error("Error retrieving client IP", error);
      return ""; // Return empty string in case of error
    }
  };

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };

  const sendEventToServer = (eventData) => {
    apiClient.post('DebtorTracker', eventData)
      .then(() => {
      })
      .catch(error => {
        console.error("Error logging event", error);
      });
  };

  const trackEvent = async (eventType, additionalData = {}) => {
    if (!shouldTrack.value) return; // Do not track if shouldTrack is false

    const ip = await fetchIp();

    const data = {
      eventId: generateUUID(),
      url: window.location.href,
      event: eventType,
      userIp: ip,
      ...additionalData,
      invoiceId: invoiceId
    };
    sendEventToServer(data);
  };

  return {
    trackEvent,
    userIp
  }
}
