import axios from 'axios'
import VueAxios from 'vue-axios'
import 'tailwindcss/tailwind.css'
import './assets/main.css' 
import router from './router'
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

const app = createApp(App);

app.config.globalProperties.$formatCurrency = function(value) {
  return new Intl.NumberFormat(this.$locale, { style: 'decimal', minimumFractionDigits: 2 }).format(value);
};

if(navigator.language) {
  app.config.globalProperties.$locale = navigator.language;
} else {
  app.config.globalProperties.$locale = 'en-US'; // or some other default
}

app.use(VueAxios, axios);
app.use(router);
app.use(store);
app.mount('#app');
