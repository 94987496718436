<template>
  <div>
    <div>
      <h2 class="text-gray-500 text-lg">
        Bank account details
      </h2>
      <p>We work together with QadirCollect who is managing payments in the debt collection process. Please use the following payment instructions:</p>
      <div class="divide-y divide-gray-400 mt-4 border-b border-gray-400">
        <!-- Loop through the bank account entries -->
        <div 
          class="py-2 flex justify-between" 
          v-for="(value, key) in bankAccount" 
          :key="key"
        >
          <span class="text-gray-500">{{ key }}</span> <!-- Display the key as label -->
          
          <!-- Check if the value is a URL -->
          <a 
            v-if="isUrl(value)" 
            @click="handleLinkClick(value)" 
            class="text-gray-500 underline flex items-center space-x-2 cursor-pointer"
          >
            <!-- Display custom text instead of the actual URL -->
            <span>How to make a bank transfer</span>
          </a>
          
          <!-- If the value is not a URL, display it normally -->
          <span 
            v-else 
            class="text-gray-500 text-base max-w-sm text-right rounded-sm bg-copy-paste-yellow"
            :style="{ wordWrap: 'break-word', overflowWrap: 'break-word' }"
          >
            {{ value }} <!-- Display the value -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bankAccount: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    isUrl(value) {
      try {
        new URL(value);
        return true;
      } catch (e) {
        return false;
      }
    },
    handleLinkClick(url) {
      if (this.isUrl(url)) {
        // Open the URL in a new browser tab
        window.open(url, '_blank', 'noopener,noreferrer');
      } else {
        console.error("Invalid URL:", url);
      }
    },
  },
};
</script>
