import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'XApiKey': process.env.VUE_APP_DEBITURA_APIKEY,
  },
});

export default apiClient;
 