<template>
    <PageContainer>
        <div class="space-y-4">
            <div class="space-y-4">

                <!-- SVG and Confirmation message centered -->
                <div class="flex flex-col items-center justify-center mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
                        <circle cx="29" cy="29" r="21.75" stroke="#23B47E" />
                        <path d="M19.3337 29L26.5837 36.25L38.667 21.75" stroke="#23B47E" />
                    </svg>
                    <p class="text-gray-500 mt-6 text-center">Confirmation - the case has been paid and is closed.</p>
                </div>

                <div class="mt-8 pt-3 pb-4">
                    <div>
                        <div class="w-full h-[1px] bg-gray-500 bg-opacity-30 mt-4 mb-4"></div>
                    </div>
                </div>

                <div class="divide-y divide-gray-200">
                    <div class="py-2 flex justify-between">
                        <span class="text-gray-500">Remaining balance:</span>
                        <span class="text-gray-500">{{ $formatCurrency(invoice.remainder) }} {{ currency.currencyCode }}</span>
                    </div>
                    <div class="py-2 flex justify-between">
                        <span class="text-gray-500">Payment status:</span>
                        <span class="text-gray-500">
                            <StatusLabel :text="statusLabel" type="success" />
                        </span>
                    </div>
                    <div class="w-full h-[1px] bg-gray-500 bg-opacity-30"></div>
                </div>
            </div>

            <ContactLink />
        </div>
    </PageContainer>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import 'vue-loading-overlay/dist/css/index.css';
import PageContainer from '../components/finalStates/FinalStatePageContainer.vue';
import ContactLink from '../components/finalStates/FinalStateContactLink.vue';
import StatusLabel from '../components/StatusLabel.vue'

export default {
    components: {
        PageContainer,
        ContactLink,
        StatusLabel,
    },
    data() {
        return {
            isModalVisible: false,
        }
    }, 
    computed: {
        ...mapState([
            "invoice",
            "currency"
        ]),
        id() {
            return this.$route.params.id;
        },
        statusLabel() {
            return this.invoice.invoiceState === 2 ? 'Closed' : 'Confirmed';
        }
    },
    watch: {
        id: {
            immediate: true,
            handler(newId) {
                if (newId) {
                    this.fetchInvoiceData(newId)
                        .catch(error => console.error(error));
                }
            },
        },
    },
    methods: {
        ...mapActions([
            "fetchInvoiceData"
        ]),
    },
}

</script>