<template>
    <div class="flex flex-col">
        <div class="w-full sm:max-w-lg border border-gray-300 bg-white rounded shadow overflow-auto">
            <div v-if="currentStep == 1">
                <form @submit.prevent="submitForm" class="space-y-4 p-4">
                    <div v-for="(option, index) in options" :key="index" class="flex items-center">
                        <input v-model="selected" :value="index" :id="'option' + index" class="mr-2" type="radio" />
                        <label :for="'option' + index">
                            <p class="text-black font-semibold text-base">{{ option.title }}</p>
                            <p class="text-gray-400 font-normal text-sm">{{ option.description }}</p>
                        </label>
                    </div>

                    <button class="w-full py-2 bg-main-cta text-white rounded">Next</button>
                </form>
            </div>
            <div v-else-if="currentStep == 2">
                <div class="p-4 space-y-4">
                    <p class="text-gray-500">Thanks for choosing this installment plan. To confirm the installment plan,
                        please insert your email</p>
                    <div class="flex items-center border border-gray-300 rounded px-3 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            class="mr-2 text-indigo-500">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3.5 4.99502C3.22614 4.99502 3 5.22004 3 5.49254V18.5075C3 18.78 3.22614 19.005 3.5 19.005H20.5C20.7739 19.005 21 18.78 21 18.5075V5.49254C21 5.22004 20.7739 4.99502 20.5 4.99502H3.5ZM2 5.49254C2 4.67051 2.67386 4 3.5 4H20.5C21.3261 4 22 4.67051 22 5.49254V18.5075C22 19.3295 21.3261 20 20.5 20H3.5C2.67386 20 2 19.3295 2 18.5075V5.49254Z"
                                fill="#6771E8" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M21.9089 6.2123C22.0679 6.43813 22.0136 6.75003 21.7878 6.90895L12.8984 13.1644C12.365 13.6182 11.6296 13.6117 11.0941 13.1661L2.21208 6.90879C1.98633 6.74976 1.93225 6.43783 2.09129 6.21208C2.25033 5.98633 2.56226 5.93225 2.788 6.09129L11.688 12.3613C11.7012 12.3706 11.714 12.3806 11.7263 12.3912C11.9015 12.542 12.1004 12.5358 12.2585 12.3957C12.2725 12.3834 12.2871 12.3719 12.3023 12.3611L21.2123 6.09114C21.4381 5.93222 21.75 5.98646 21.9089 6.2123Z"
                                fill="#6771E8" />
                        </svg>
                        <input v-model="email" type="email" placeholder="Insert your email"
                            class="w-full focus:outline-none" />
                    </div>
                    <div class="mt-2" v-if="isFutureCommunicationsVisible">
                        <input v-model="futureCommunications" type="checkbox" id="futureCommunications" class="mr-2">
                        <label for="futureCommunications" class="text-gray-500">Should future communications be sent to this
                            email?</label>
                    </div>
                    <div class="flex justify-between">
                        <button @click="goBack"
                            class="w-2/5 py-2 inline-flex justify-center rounded-lg border border-blue-600 text-main-cta hover:bg-blue-50 focus:outline-none mr-2">
                            Back
                        </button>
                        <button @click="submitEmail" class="w-3/5 py-2 bg-main-cta text-white rounded">Confirm
                            Email</button>
                    </div>
                </div>
            </div>

            <div v-else-if="currentStep == 3">
                <div class="p-4 space-y-4">
                    <div v-if="errorMessage"
                        class="error-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {{ errorMessage }}
                    </div>
                    <div class="flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="9" stroke="#23B47E" />
                            <path d="M8 12L11 15L16 9" stroke="#23B47E" />
                        </svg>
                    </div>
                    <p class="text-gray-500"><b>Please check your email</b> with the terms of the installment plan, and
                        confirm it
                        by inserting the
                        verification code</p>
                    <div class="flex items-center border border-gray-300 rounded px-3 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            class="mr-2 text-indigo-500">
                            <path
                                d="M12.773 3.70613L18.7577 5.30204C19.1954 5.41878 19.5 5.81523 19.5 6.26828V12.2889C19.5 14.295 18.4974 16.1684 16.8282 17.2812L12.5547 20.1302C12.2188 20.3541 11.7812 20.3541 11.4453 20.1302L7.1718 17.2812C5.50261 16.1684 4.5 14.295 4.5 12.2889V6.26828C4.5 5.81523 4.80458 5.41878 5.24234 5.30204L11.227 3.70613C11.7335 3.57107 12.2665 3.57107 12.773 3.70613Z"
                                stroke="#6771E8" stroke-linecap="round" />
                            <path d="M9.5 11.5L11.3232 13.3232C11.4209 13.4209 11.5791 13.4209 11.6768 13.3232L15 10"
                                stroke="#6771E8" stroke-linecap="round" />
                        </svg>
                        <input v-model="verificationCode" type="text" placeholder="Insert verification code"
                            class="w-full focus:outline-none" />
                    </div>
                    <div class="flex justify-between">
                        <button @click="goBack"
                            class="w-2/5 py-2 inline-flex justify-center rounded-lg border border-blue-600 text-main-cta hover:bg-blue-50 focus:outline-none mr-2">Back</button>
                        <button @click="submitVerificationCode" class="w-3/5 py-2 bg-main-cta text-white rounded">Confirm
                            verification code</button>
                    </div>
                </div>
            </div>

            <div v-else-if="currentStep == 4">
                <div class="p-4 space-y-4">
                    <div class="flex items-center justify-center mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="9" stroke="#23B47E" />
                            <path d="M8 12L11 15L16 9" stroke="#23B47E" />
                        </svg>
                    </div>
                    <p class="text-center text-gray-500">
                        Confirmation - thanks for signing the installment plan. We will send you monthly payment
                        notifications.
                    </p>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center">
            <div class="w-20 flex justify-between my-4">
                <div v-for="(step, index) in steps" :key="index" class="w-2 h-2 rounded-full"
                    :class="index == currentStep - 1 ? 'bg-indigo-500' : 'bg-gray-300'"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import apiClient from "../../services/apiClient";

export default {
    name: "SelectInstallment",
    props: ['installmentPlans', 'debtorEmail', 'invoiceId'],
    data() {
        return {
            verificationCode: "",
            steps: [1, 2, 3, 4],
            currentStep: 1,
            selected: null,
            email: '',
            originalEmail: this.debtorEmail || '',
            errorMessage: null,
            futureCommunications: true,
        }
    },

    computed: {
        isFutureCommunicationsVisible() {
            return this.originalEmail && this.email !== this.originalEmail;
        },
    },
    mounted() {
        if (this.$route.query.accessCode) {
            this.verificationCode = this.$route.query.accessCode;
            this.submitVerificationCode();
        }
    },
    watch: {
        debtorEmail: {
            immediate: true,
            handler(newValue) {
                this.originalEmail = newValue;
                this.email = newValue || '';
                // Always true if debtorEmail is null, undefined or empty
                this.futureCommunications = newValue ? false : true;
            },
        },
        email: {
            immediate: false,
            handler(newValue) {
                if (this.originalEmail) { // Only change futureCommunications if originalEmail has value
                    this.futureCommunications = newValue === this.originalEmail ? false : true;
                }
            },
        },
        installmentPlans: {
            immediate: true,
            handler(newValue) {
                this.options = newValue.map(plan => ({
                    title: `Pay ${plan.monthlyPayment.toFixed(2)} ${plan.currency} monthly for ${plan.months} months`,
                    description: `10% interest, total financing costs ${plan.totalFinancingCosts.toFixed(2)} ${plan.currency}`
                }));
                if (this.options.length > 0) {
                    this.selected = 0;
                }
            },
        },
        currentStep: {
            immediate: false,
            handler() {
                this.resetErrorMessage();
            }
        },

    },
    methods: {
        goBack() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        async submitEmail() {
            if (this.email && this.currentStep < this.steps.length) {
                const requestBody = this.constructRequestBody();
                try {
                    const response = await this.submitToAPI('/Installment/request-installment', requestBody);
                    if (response.data) {
                        this.currentStep++;
                    }
                } catch (error) {
                    console.error('Failed to make API request:', error);
                }
            }
        },

        submitForm() {
            if (this.currentStep < this.steps.length) {
                this.currentStep++;
            }
        },
        isVerificationStepValid() {
            return this.verificationCode && this.currentStep < this.steps.length;
        },
        constructRequestBody() {
            return {
                monthsRequest: this.installmentPlans[this.selected].months,
                invoiceId: this.invoiceId,
                email: this.email,
                updateDebtorsEmail: this.futureCommunications,
            };
        },

        displayError(message) {
            this.errorMessage = message;
        },

        resetErrorMessage() {
            this.errorMessage = null;
        },

        async submitToAPI(endpoint, requestBody) {
            this.$store.dispatch('startRequest');
            try {
                const response = await apiClient.post(endpoint, requestBody);
                this.$store.dispatch('endRequest');
                return response;  // Return entire response
            } catch (error) {
                this.$store.dispatch('endRequest');
                throw error;
            }
        },


        async submitVerificationCode() {
            if (this.isVerificationStepValid()) {
                this.$store.dispatch('startRequest');
                this.resetErrorMessage(); // reset error message
                try {
                    const requestBody = {
                        verificationCode: this.verificationCode,
                        ip: await this.getIPAddress(),
                    };

                    const response = await this.submitToAPI('/Installment/sign', requestBody);

                    if (this.verificationCode.trim() === '') {
                        this.displayError('This is not the correct verification code');
                        return;
                    }

                    // Only go to step 4 if it's a successful call
                    if (response && response.status >= 200 && response.status < 300) {
                        this.currentStep = 4;
                    } else {
                        throw new Error('Could not find installment'); // throw an error if the status code is not successful
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        this.displayError('This is not the correct verification code');
                    } else {
                        this.displayError(error.message);
                    }
                } finally {
                    this.$store.dispatch('endRequest');
                }
            } else {
                this.displayError('This is not the correct verification code');
            }
        },

        async getIPAddress() {
            try {
                const response = await apiClient.get('https://api.ipify.org?format=json');
                return response.data.ip;
            } catch (error) {
                return '';
            }
        }

    }
}
</script>