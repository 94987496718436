<template>
  <div class="container">
    <div class="w-full sm:w-3/4 mx-auto p-2">
      <nav class="border-gray-200 px-2 py-4 mb-4">
        <div class="container mx-auto flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
          <span class="text-2xl font-bold mb-2 sm:mb-0">{{ creditor.name }}</span>
          <a @click="navigateHome"
            class="flex justify-start sm:justify-end items-center text-gray-600 text-xs font-normal">
            Powered by: <img src="@/assets/img/QadirCollect.png" class="ml-2" width="180" />
          </a>
        </div>
      </nav>

    </div>
    <div class="w-full sm:w-3/4 mx-auto p-2 flex flex-col xl:flex-row">
      <div class="flex xl:flex-grow">
        <div class="flex flex-col xl:flex-row">
          <div class="w-full xl:flex-grow xl:w-3/5 lg:w-6/10 bg-white p-6 xl:p-12 rounded-md shadow-md">
            <div class="space-y-4">
              <h1 class="text-xl font-bold">Dear {{ debtor.name }}</h1>
              <p>Welcome to our digital debt collection portal, powered by QadirCollect.</p>
              <p>

                We're contacting you regarding an outstanding payment of {{ $formatCurrency(invoice.remainder) }} {{
                  currency.currencyCode }} that is overdue.
              </p>
              <p>
                To prevent any additional expenses or the need for legal action, we invite you to settle the balance
                promptly. Alternatively, you can easily create an installment plan right here on this portal.
              </p>
              <p>We're committed to working with you to resolve this matter in a way that is manageable for your financial
                situation.</p>
            </div>
            <div class="mt-8">
              <div>
                <div class="w-full h-[1px] bg-gray-500 bg-opacity-30"></div>
              </div>
            </div>

            <h2 ref="paymentSection" class="text-gray-500 text-xl font-semibold mt-8">
              Select a payment method
            </h2>


            <div class="flex mt-6">
              <div v-for="(method, index) in paymentMethods" :key="index" :class="[
                'w-full max-w-[175px] h-16 flex flex-col items-start justify-center py-4 px-4 rounded-md border transition-all duration-300 space-y-2 mr-4 last:mr-0 overflow-hidden',
                { 'border-black opacity-100': method.selected, 'border-gray-300 opacity-30': !method.selected }
              ]" @click="selectPaymentMethod(index)">

                <img :src="method.icon" :class="{ 'w-6 h-6': method.icon }" alt="payment method icon" />
                <span class="text-xs sm:text-base">{{ method.name }}</span>

              </div>
            </div>


            <br><br>
            <BankPaymentInstructions :bankAccount="bankAccount" v-if="isBankInstructionVisible && !accessCodeFound" />
            <SelectInstallment v-if="isInstallmentActive || accessCodeFound" :installment-plans="installmentPlans"
              :debtorEmail="debtorfullEmail" :invoiceId="invoice.id" @loading="updateLoading" />


          </div>
          <div class="w-full xl:flex-grow mt-6 xl:mt-0 xl:w-2/5 lg:w-4/10 xl:ml-6 flex flex-col">
            <div class="bg-white p-12 rounded-md shadow-md">
              <h1 class="text-xl mb-4">Your balance</h1>
              <div class="text-4xl text-owed-money mb-6 flex justify-between items-center">
                <div>
                  {{ $formatCurrency(invoice.remainder) }}
                  <span class="text-base">{{ currency.currencyCode }}</span>
                </div>
                <StatusLabel text="Past due" type="danger" />
              </div>

              <div>
                <div class="py-2 flex justify-between">
                  <span class="text-gray-500">Due date:</span>
                  <span class="text-gray-500">{{ formattedDueDate }}</span>
                </div>
                <div class="py-2 flex justify-between">
                  <span class="text-gray-500">Case reference:</span>
                  <span class="text-gray-500">{{ invoice.reference }}</span>
                </div>
                <div class="py-2 flex justify-between">
                  <span class="text-gray-500">Creditor:</span>
                  <span class="text-gray-500">{{ creditor.name }}</span>
                </div>

              </div>
              <br>
              <div class="divide-y divide-gray-400">
                <div v-for="item in invoiceItems.items" :key="item.date" class="py-2 flex justify-between">
                  <span class="text-gray-500">{{ convertTypeToString(item.type) }}:</span>
                  <span class="text-gray-500">{{ $formatCurrency(item.grossAmount) }} {{ currency.currencyCode }}</span>
                </div>
              </div>
              <div class="border-t border-b border-gray-400">
                <div class="py-2 flex justify-between font-semibold ">
                  <span>Total</span>
                  <span>{{ $formatCurrency(invoice.remainder) }} {{ currency.currencyCode }}</span>
                </div>
              </div>


            </div>

            <div class="mt-6 bg-white p-12 rounded-md shadow-md flex flex-col space-y-4 flex-grow justify-center">
              <a @click="() => { applyForInstallment(); scrollToPaymentSection(); }"
                class="text-gray-500 underline flex items-center space-x-2 cursor-pointer">
                <img src="../assets/img/designassets/Calendar.svg" alt="Icon" class="h-5 w-5" />
                <span>Apply for installment</span>
              </a>
              <a class="text-gray-500 underline flex items-center space-x-2 cursor-pointer"
                @click="isDisputeModalVisible = true">
                <img src="../assets/img/designassets/Stop.svg" alt="Icon" class="h-5 w-5" />
                <span>Dispute debt</span>
              </a>
              <a class="text-gray-500 underline flex items-center space-x-2 cursor-pointer"
                @click="isModalVisible = true">
                <img src="../assets/img/designassets/Email.svg" alt="Icon" class="h-5 w-5" />
                <span>Contact Qadir Collections</span>
              </a>
            </div>
          </div>
        </div>


        <QadirContact v-if="isModalVisible" @close="isModalVisible = false" />
        <RegisterDispute v-if="isDisputeModalVisible" @close="isDisputeModalVisible = false" :invoiceId="invoice.id"
          @loading="updateLoadingStatus" />

        <loading :active="isLoading" :can-cancel="false" loader="dots" color="#000000" :opacity="0.85"
          background-color="#F0F2F8">
        </loading>


      </div>
    </div>
    <div class="w-full sm:w-3/4 mx-auto p-2 flex flex-col xl:flex-row">
      <div class="flex xl:flex-grow">
        <div class="flex flex-col xl:flex-row">
          <!-- Left Column (3/5) -->
          <div class="w-full xl:flex-grow xl:w-3/5 lg:w-6/10">
            <div class="text-gray-600 text-xs font-normal mt-4">
              QadirCollect.com is a software solution used by {{ creditor.name }} for debt collection.
              QadirCollect.com is not a debt collection agency or law firm, and your claim is collected via first party
              collections by your creditor {{ creditor.name }}.
            </div>
          </div>

          <!-- Right Column (2/5) -->
          <div class="w-full xl:flex-grow mt-6 xl:mt-0 xl:w-2/5 lg:w-4/10 xl:ml-6">
            <!-- ... existing content of right column ... -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BankPaymentInstructions from "../components/landingPage/BankPaymentInstructions.vue";
import QadirContact from '../components/QadirContact.vue';
import RegisterDispute from '../components/landingPage/RegisterDispute.vue';
import SelectInstallment from "../components/landingPage/SelectInstallment.vue";
import { PAYMENT_METHODS } from '../constants/paymentMethods.js';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import StatusLabel from '../components/StatusLabel.vue'
import apiClient from "../services/apiClient";
import userEventTracking from "../composables/userEventTracking.js";

export default {
  components: {
    BankPaymentInstructions,
    QadirContact,
    RegisterDispute,
    SelectInstallment,
    Loading,
    StatusLabel,
  },
  props: ["id"],
  data() {
    return {
      paymentMethods: [
        { id: PAYMENT_METHODS.BANK_TRANSFER, name: 'Bank transfer', selected: true, icon: require('../assets/img/designassets/Bankselected.svg') },
        { id: PAYMENT_METHODS.INSTALLMENTS, name: 'Pay in installments', selected: false, icon: require('../assets/img/designassets/Calendar.svg') },
      ],
      invoiceItems: {
        items: [],
      },
      isBankInstructionVisible: false,
      isModalVisible: false,
      isDisputeModalVisible: false,
      installmentPlans: [],
      formSubmitted: false,
      accessCodeFound: false,
      isInstallmentFetched: false,
      bankAccount: null,
      localId: this.id,
    };
  },


  computed: {
    ...mapState([
      "debtor",
      "creditor",
      "invoice",
      "collectionPartner",
      "currency",
      "debtorfull",
    ]),
    ...mapGetters([
      "isLoading"
    ]),
    debtorfullEmail() {
      return this.debtorfull ? this.debtorfull.email : null;
    },
    isInstallmentActive() {
      const installmentMethod = this.paymentMethods.find(method => method.name === 'Pay in installments');
      return installmentMethod && installmentMethod.selected && this.isInstallmentFetched;
    },
    formattedDueDate() {
      const dueDate = this.invoice.masterInvoiceMetaData.latestDueDate;
      if (!dueDate) {
        return "-";
      }
      return new Date(dueDate).toLocaleDateString();
    },
  },
  methods: {
    goToPayPage() {
      this.$router.push(`/pay/${this.localId}`);
    },
    applyForInstallment() {
      const installmentIndex = this.paymentMethods.findIndex(method => method.id === 'installments');
      if (installmentIndex !== -1) {
        this.selectPaymentMethod(installmentIndex);
      }
    },
    selectPaymentMethod(index) {
      this.paymentMethods = this.paymentMethods.map((method, i) => ({
        ...method, // include all existing fields
        selected: i === index // only update the selected field
      }));


      // Check if 'Bank Account' is selected, if so fetch bank account data
      if (this.paymentMethods[index].name === 'Bank transfer') {
        this.isBankInstructionVisible = true;
        this.fetchBankAccount();
      } else {
        this.isBankInstructionVisible = false;
      }

      if (this.paymentMethods[index].name === 'Pay in installments') {
        this.fetchInstallmentPlans();
      }
    },

    fetchInstallmentPlans() {
      this.$store.dispatch("startRequest");
      apiClient.get(`/Installment/default-plans/${this.localId}`)
        .then(response => {
          this.installmentPlans = response.data;
          this.isInstallmentFetched = true;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.$store.dispatch("endRequest");
        });
    },

    async loadDataBasedOnLocalId() {
      // Your existing logic to fetch data based on localId
      await this.fetchBankAccount();
      this.isBankInstructionVisible = true;
      this.fetchInvoiceItems();
      // Add any other methods that need to be called after setting localId
    },

    async fetchInvoiceDataByCaseId(caseid) {
      try {
        const response = await apiClient.get(`/Invoice/case-reference/${caseid}`);
        this.localId = response.data.id;
        // Call other methods that depend on localId here
        await this.loadDataBasedOnLocalId();
      } catch (error) {
        console.error(error);
      }
    },

    async fetchBankAccount() {
      const selectedMethodAtStart = this.paymentMethods.find(method => method.selected);
      try {
        this.$store.dispatch("startRequest");
        const response = await apiClient.get(`/CreditorCaseBankDetails/ForJson/${this.creditor.id}`);
        
        response.data = {
          'Case reference': this.invoice.reference,
          ...response.data,
        };

        this.bankAccount = response.data;

        const currentSelectedMethod = this.paymentMethods.find(method => method.selected);
        if (this.bankAccount && currentSelectedMethod.id === selectedMethodAtStart.id) {
          this.isBankInstructionVisible = true;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("endRequest");
      }
    },
    async safeDispatch(action, payload) {
      try {
        await this.$store.dispatch(action, payload);
      } catch (error) {
        console.error(`Error during dispatching ${action}:`, error);
      }
    },
    fetchInvoiceItems() {
      this.$store.dispatch("startRequest");
      apiClient
        .get(
          `/Invoice/economical-overview/${this.localId}`
        )
        .then((response) => {
          this.invoiceItems = response.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.$store.dispatch("endRequest");
        });
    },
    convertTypeToString(type) {
      const typeMap = {
        0: "Principal",
        1: "Payment",
        2: "Fee",
        3: "Interest",
      };
      return typeMap[type];
    },

    updateLoading() {
      this.formSubmitted = !this.isLoading;
    },
    updateLoadingStatus() {
      this.formSubmitted = !this.isLoading;
    },
    scrollToPaymentSection() {
      this.$refs.paymentSection.scrollIntoView({ behavior: 'smooth' });
    },
    closeModal() {
      if (this.formSubmitted) {
        this.isDisputeModalVisible = false;
        this.formSubmitted = false; // reset the flag
      }
    },
  },
  async mounted() {
    try {
      this.$store.dispatch("startRequest");

      if (this.$route.query.caseid) {
        await this.fetchInvoiceDataByCaseId(this.$route.query.caseid);
      } else {
        this.localId = this.$route.params.id;
        await this.loadDataBasedOnLocalId();
        await this.safeDispatch("fetchInvoiceData", this.localId)
          .catch((error) => console.error(`Error during dispatching fetchInvoiceData:`, error));

        await this.safeDispatch("fetchDebtorData", this.debtor.id)
          .catch((error) => console.error(`Error during dispatching fetchDebtorData:`, error));

        await this.fetchBankAccount();
        this.isBankInstructionVisible = true;
        this.fetchInvoiceItems();

        if (this.$route.query.accessCode) {
          this.accessCodeFound = true;
          this.applyForInstallment();
        }

        if (this.$route.query.preload === 'dispute') {
          this.isDisputeModalVisible = true;
        } else if (this.$route.query.preload === 'installment') {
          this.applyForInstallment();
        }

        const { trackEvent } = userEventTracking(this.localId, this.$store);
        trackEvent('page_view', { pageName: 'LandingPage' });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.$store.dispatch("endRequest");
    }
  },
  watch: {
    id(newId) {
      this.localId = newId;
    },
  },

};
</script>

<style>
.container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>