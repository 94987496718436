<template>
  <div id="app" class="w-full">
    <Loading :active="isLoading" :can-cancel="false" loader="dots" color="#000000" :opacity="0.85" background-color="#F0F2F8" />

    <router-view />

    <div class="my-16"></div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters([
      "isLoading"
    ]),
  },
  mounted() {
    document.title = 'Payment platform for outstanding debt';
  },
};
</script>

<style>
body {
  @apply bg-slate-100;
}
</style>
