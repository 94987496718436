import { createStore } from "vuex";
import apiClient from "./services/apiClient";

export default createStore({
  state: {
    debtor: {},
    debtorfull: {},
    creditor: {},
    invoice: {},
    collectionPartner: {},
    currency: {},
    activeRequests: 0,
    shouldTrack: true, 
  },
  mutations: {
    SET_INVOICE(state, invoice) {
      state.invoice = invoice;
    },
    SET_DEBTOR(state, debtor) {
      state.debtor = debtor;
    },
    SET_DEBTORFULL(state, debtor) {
      state.debtorfull = debtor;
    },
    SET_CREDITOR(state, creditor) {
      state.creditor = creditor;
    },
    SET_COLLECTION_PARTNER(state, collectionPartner) {
      state.collectionPartner = collectionPartner;
    },
    SET_CURRENCY(state, currency) {
      state.currency = currency;
    },
    incrementRequests(state) {
      state.activeRequests += 1;
    },
    decrementRequests(state) {
      state.activeRequests -= 1;
    },
    SET_TRACKING_STATUS(state, value) {
      state.shouldTrack = value;
    },
  },
  actions: {
    async fetchInvoiceData({ commit, dispatch }, id) {
      try {
        dispatch("startRequest");
        const response = await apiClient.get(`/Invoice/${id}`);

        commit("SET_INVOICE", response.data);
        commit("SET_DEBTOR", response.data.debtor);
        commit("SET_CREDITOR", response.data.creditor);
        commit("SET_COLLECTION_PARTNER", response.data.collectionPartner);
        commit("SET_CURRENCY", response.data.currency);

        // fetch debtor data after invoice data has been committed
        await dispatch("fetchDebtorData", response.data.debtor.id);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch("endRequest");
      }
    },

    async fetchDebtorData({ commit, dispatch }, debtorId) {
      try {
        dispatch("startRequest");
        const response = await apiClient.get(`/Debtor/${debtorId}`);
        commit("SET_DEBTORFULL", response.data);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch("endRequest");
      }
    },
    startRequest({ commit }) {
      commit("incrementRequests");
    },
    endRequest({ commit }) {
      commit("decrementRequests");
    },
  },
  getters: {
    isLoading: (state) => state.activeRequests > 0,
  },
});
